:root {
  --blue100: #e1f9fe;
  --blue300: #6be4ff;
  --blue800: #0989a5;

  --grey200: #eaecf0;
  --grey300: #d0d5dd;
  --grey400: #98a2b3;
  --grey800: #1c273b;
  --grey900: #101828;

  --green50: #e7f4f2;
  --green600: #4f9e88;
}
